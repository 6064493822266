<template>
    <div class="settings-form">
        <div class="settings-form__title" v-if="data.title"> {{ data.title }} </div>
        <div class="settings-form__inputs">
            <InputGroup 
                class="settings-form__input" 
                :style="data.style || ''"
                :data="item"
                v-model="item.value"
                v-for="(item, index) in data.inputs"
                :key="`input-${index}`"
                >
                <router-link class="input__link link_dotted" :to="item.link.href"  v-if="item.link">{{ item.link.title }}</router-link>
            </InputGroup>
        </div>
        <Button class="settings-form__btn" v-if="data.btn" :data="data.btn" @click="$emit('onClick')"/>
    </div>
</template>

<script>
import InputGroup from '@/components/form/InputGroup'
import Button from '@/components/form/Button'
import AddressAutocomplete from '@/components/item-wizard/components/form/AddressAutocomplete'
import { ref } from 'vue'

export default ({
    props: ['data', 'states'],
    components: {
        InputGroup,
        Button,
        AddressAutocomplete
    },
})
</script>

<style scoped lang="scss">
.settings-form {
    // display: flex;
    // flex-wrap: wrap;
    &__title {
        font-size: 24px;
        line-height: 100%;
        text-transform: uppercase;
        color: #1C2833;
        width: 100%;
        text-align: left;
        font-weight: bold;
        margin-bottom: 8px;
        font-family: Sansation;
    }
    &__inputs {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    &__input {
        margin-bottom: 5px;
      &--width {
        width: 306px;
      }
    }
    &__btn {
        margin-top: 16px;
        width: 100%;
    }
}
.input__link {
    position: absolute;
    right: -24px;
    top: 50%;
    transform: translate(100%, -50%);
    color: var(--SecondaryButtonColour);
}
@media (max-width: 768px) {
  .input__link {
    position: relative;
    right: 0;
    text-align: left;
    left: 0;
    top: auto;
    padding: 10px;
    transform: none;
    display: block;
  }
  .settings-form__input {
    &--width {
      width: 100%
    }
  }
}
</style>