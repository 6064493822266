import { AJAX_POST } from '@/helpers/ajax.js'

export default {
    async getCurrentUserData(payload) {
        return await AJAX_POST('/current-user', payload)
    },
    async updDetails(payload) {
        return await AJAX_POST('/update-details', payload)
    },
    async changeEmail(payload) {
        return await AJAX_POST('/change-email', payload)
    },
    async changePassword(payload) {
        return await AJAX_POST('/change-password', payload)
    },
    
}