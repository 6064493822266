<template>
    <div class="form-group" :class="{error: data.error}">
        <label class="form-group__label" for="" v-if="data.label">{{ data.label }}</label>
        <div class="form-group__input-container">
            
            <input v-if="data.mask" class="form-group__input"
                v-mask="data.mask"
                :value="modelValue"
                :type="data.type"
                :min="data.min"
                :placeholder="data.placeholder"
                :disabled="data.disabled"
                @focus="data.error = ''"
                @input="input"
                :class="{'password_padding': data.type === 'password' || data.initial_type === 'password'}">

            <input v-else class="form-group__input"
                :value="modelValue"
                :type="data.type"
                :min="data.min"
                :placeholder="data.placeholder"
                :disabled="data.disabled"
                @focus="data.error = ''"
                @input="input"
                :class="{'password_padding': data.type === 'password' || data.initial_type === 'password'}">

          <svg-icon-qouting
              :key="key"
              v-if="data.type === 'password' || data.initial_type === 'password'"
              :data="data.type === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"
              @click="data.initial_type = 'password' ;key++;data.type === 'password' ? data.type = 'text' : data.type = 'password' "
              class="form-group__input__password_toggle"
          />

            <slot></slot>
        </div>
        <div class="form-group__error" v-if="data.error">{{ data.error }}</div>
    </div>
</template>

<script>

import {ref} from "vue";
import {mask} from 'vue-the-mask'

export default({
    directives: {mask},
    props: ['data', 'modelValue', 'filter'],
    setup(props, { emit }) {
        let key = ref(0);
        function input(event) {
            let data = event.target.value
            if(props.filter) data = props.filter(data)
            props.modelValue = data
            emit('update:modelValue', data)
        }

        return {
            input,
          key
        }
    },
})
</script>


<style scoped lang="scss">

.password_padding {
  padding-right: 34px !important;
}
.form-group {
    width: 100%;
    &__label {
        font-weight: bold;
        font-size: 18px;
        line-height: 140%;
        display: block;
        margin: 0 0 2px 4px;
        text-align: left;
    }
    &__input {
        border: 1px solid rgba(11, 53, 83, 0.2);
        border-radius: 2px;
        height: 40px;
        width: 100%;
        padding: 0 13px 0 15px;
        font-weight: bold;
        font-size: 17px;
        line-height: 140%;
        &::placeholder {
            font-style: italic;
            font-weight: normal;
        }
        &-container {
            position: relative;

        }
      &__password_toggle{
        align-items: center;
        background: none;
        border: none;
        bottom: 0;
        display: flex;
        margin-right: 0.75rem;
        outline: none;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer !important;
      }
    }
    &__error {
        font-size: 14px;
        line-height: 140%;
        color: #DB5151;
        text-align: left;
    }
}
</style>
