<template>
    <div class="settings container_content">
        <div class="settings__title">MY ACCOUNT</div>
        <div class="settings__block contact__info">
            <div>
                <Form class="block__item" :data="detailsData" />
                <Button class="contact-info__btn desktop" :data="detailsBtn" @click="updDetails"/>
            </div>
            <FormAddress
                class="block__item"
                :states="states"
                :data="addressData"
                @updAddress="updAddress($event)"
                @updStates="states = $event"
            />
            <Button class="contact-info__btn mobile" :data="detailsBtn" @click="updDetails"/>
        </div>
        <div class="settings__block">
            <Form class="block__item block_security"  :data="securityData" @onClick="changePassword"/>
        </div>
    </div>
</template>

<script>
import Form from '@/components/settings/account/Form'
import FormAddress from '@/components/item-wizard/components/form/FormAddress'
import { reactive, ref } from '@vue/reactivity'
import api from '@/services/settings/account.service.js'
import { getValueFromObject, getValidationError } from '@/helpers/objects'
import { notify } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import Button from '@/components/form/Button'
import { onMounted } from '@vue/runtime-core'
import _ from "lodash";

export default({
    components: {
        Form,
        Button,
        FormAddress,
    },
    setup() {
        const store = useStore()

        const states = ref(null)

        store.commit('setField', {
            field: 'loading', value: true,
        })

        getUserData()

        const detailsBtn = reactive({
            type: 'primary', title: 'SAVE', icon: 'fas fa-check', loading: false,
        })

        const detailsData = reactive({
            title: 'MY Details',
            inputs: [
                { label: 'First Name', placeholder: 'Your First Name', value: '', key: 'first_name', type: 'text' },
                { label: 'Last Name', placeholder: 'Your Last Name', value: '', key: 'last_name', type: 'text' },
                { label: 'Mobile', mask: '#### ### ###', placeholder: 'Your mobile', value: '', key: 'mobile',type: 'text', disabled: false, error: null },
            ],
        })

        const addressData = reactive({
            title: 'Address',
            inputs: [
                { label: 'Address', component: 'address-autocomplete', placeholder: 'Enter street address', value: '', key: ['primary_address', 'address'], type: 'text', style: 'width: 306px;' },
                { label: 'Suburb', placeholder: 'Enter suburb', value: '',  key: ['primary_address', 'suburb'], type: 'text',style: 'width: 170px; margin-right: 12px;' },
                { label: 'Postcode', placeholder: 'Enter postcode', value: '',  key: ['primary_address', 'postcode'], type: 'text', style: 'width: 124px;' },
                { label: 'State', component: 'address-state', placeholder: 'Enter state', value: '',  value_id: '',key: ['primary_address', 'state'], type: 'text', style: 'width: 306px;' },
            ]
        })

        const securityData = reactive({
            title: 'Email',
            inputs: [
                { label: 'Email', placeholder: 'john.smith@email.com', value: '', key: 'email', type: 'text', disabled: true, link: { title: 'CHANGE EMAIL', href: '/update-email-address' } },
                { label: 'New Password', placeholder: 'New Password', value: '', key: 'password', type: 'password' },
                { label: 'Confirm Password', placeholder: 'Confirm Password', key: 'password_confirmation', value: '', type: 'password' },
            ],
            btn: { type: 'primary', title: 'change password', icon: 'fas fa-check', loading: false },
        })

        async function getUserData() {
            const result  = await api.getCurrentUserData()

            if(result.data) {
                const inputs = [...detailsData.inputs, ...securityData.inputs]
                const data  = result.data

                for(const key in data) {
                    const item = inputs.find(el => el.key == key)
                    if(item) item.value = data[key]
                }

                updAddress(data.primary_address)
            }

            store.commit('setField', {
                field: 'loading', value: false,
            })
        }

        async function updDetails() {
            const inputs = [...detailsData.inputs, ...addressData.inputs]
            const payload = getValueFromObject(inputs)


            const mobileInput = inputs.find(el => el.key == 'mobile')

            if(mobileInput) {
                const val = mobileInput.value?.replace(/\s/g, '')
                if(!val || val?.length < 10) {
                    mobileInput.error = 'Mobile number must be at least 10 characters'
                    detailsBtn.loading = false
                    return
                }   else {
                    payload.mobile = val
                    mobileInput.error = ''
                }
            }

            //set value id
            const stateInput = addressData.inputs.find(el => el.key[1] == 'state')
            if(payload.primary_address.state) payload.primary_address.state = stateInput.value_id

            if(!(payload.primary_address.state) && stateInput.value){
              payload.primary_address.state = _.find(states.value,{name:stateInput.value}).id
            }

            const result = await api.updDetails(payload)

            if(result.errors) {
                getValidationError(result.errors, inputs)
            } else notify({
                type: 'success',
                title: 'Updated'
            })
            detailsBtn.loading = false
        }

        async function updAddress(primary_address) {

            for(const _key in primary_address) {
                const key = _key.split(',')[1] ?? _key.split(',')[0]
                const input = addressData.inputs.find(el => el.key[1] == key)
                const value = primary_address[_key]

                if(input && value) input.value = value
                if(input && key == 'state') {
                    if(Number.isInteger(value)) {
                        const matchStateId = states.value.find(el => el.state_id == value)
                        input.value = matchStateId.name
                    } else {
                        const matchStateName = states.value.find(el => el.name == input.value)
                        if(matchStateName) {
                            input.value = matchStateName.name
                            input.value_id = matchStateName.state_id
                        }
                    }
                }
            }
        }

        async function changePassword() {
            const inputs = securityData.inputs
            const payload = getValueFromObject(inputs)

            const result = await api.changePassword(payload)

            if(result.errors) {
                getValidationError(result.errors, inputs)
            } else notify({
                type: 'success',
                title: 'Changed'
            })
            securityData.btn.loading = false
        }

        return {
            detailsData,
            detailsBtn,
            addressData,
            securityData,
            updDetails,
            changePassword,
            updAddress,
            states
        }
    },
})
</script>


<style scoped lang="scss">
.settings__title {
    font-weight: bold;
    font-size: 31px;
    line-height: 100%;
    margin-bottom: 10px;
    text-align: left;
    font-family: Sansation;
}
.settings__block {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #C4C4C4;
    padding: 13px 0;
    // &.contact__info {
    //     .block__item {
    //         order: 2;
    //         &:last-of-type {
    //             order: 1;
    //             margin-bottom: 15px;
    //         }
    //     }
    // }
    &:last-of-type {
        border-bottom: 0;
    }
    .block__item {
        width: 325px;
        margin-right: 151px;
        @media(max-width: 1000px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
        }
    }


    .contact-info__btn {
        width: 325px;
        margin-top: 10px;
        &.mobile {
            display: none;
        }
        @media(max-width: 1000px) {
            &.mobile {
                display: flex;
                width: 100%;
            }
            &.desktop {
                display: none;
            }
        }
    }
}

</style>
